import AdvertisersList from './advertisers'
import ListAudiences from './list'
import ListAudiencesByAdvertiser from './listByAdvertiser'
import ListAudiencesByPublisher from './listByPublisher'

export const audiencesRoutes = {
  list: ListAudiences,
  listByAdvertiser: ListAudiencesByAdvertiser,
  listByPublisher: ListAudiencesByPublisher,
  advertisers: AdvertisersList
}
