import { useTranslation } from 'react-i18next'

import LanguageToggle from '~/components/LanguageToggle'
import PageTitle from '~/components/PageTitle'
import Svg from '~/components/svg'
import { useAppInfo } from '~/hooks/useAppInfo'

import ForgotPasswordForm from '../../components/ForgotPasswordForm'
// import ForgotPasswordImage from '../../components/ForgotPasswordImage'

import * as S from './styles'

const ForgotPassword = () => {
  const { t } = useTranslation()
  /**
   * Handle app info
   */
  const { logo: urlLogo, name } = useAppInfo()

  /**
   * END = Handle app info
   */

  return (
    <S.Container className="m-auto px-5 py-4 bg-white border rounded-3">
      <PageTitle title={t('auth:forgotPassword.pageTitle')} />

      <header className="text-center">
        {urlLogo ? (
          <img
            src={urlLogo}
            alt={name}
            className="mb-2 object-fit-contain"
            width="196"
          />
        ) : (
          <Svg
            src="brands/logo-newtail-vtex-vertical"
            width="196"
            style={{ marginBottom: '.5rem' }}
          />
        )}

        <div className="text-center ">
          <h3>{t('auth:forgotPassword.header.title')}</h3>
          <p>{t('auth:forgotPassword.header.subtitle')}</p>
        </div>
      </header>

      <ForgotPasswordForm />

      <footer>
        <LanguageToggle />
      </footer>
      {/* <ForgotPasswordImage /> */}
    </S.Container>
  )
}

export default ForgotPassword
