import { FiPlus } from 'react-icons/fi'

import { ButtonNavLink } from '~/components/Buttons'
import { DatepickerHeader } from '~/components/datepicker-header'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { isAdvertiser, isPublisher } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import PublisherTable from '~/modules/retailMedia/components/_tables/PublisherTable'
import DashboardRetailMediaCampaigns from '~/modules/retailMedia/components/DashboardRetailMediaCampaigns'
import MainTabs from '~/modules/retailMedia/components/MainTabs'

import AdvertisersFilters from '../../components/_tables/AdvertiserFilters'
import AdvertisersTable from '../../components/_tables/AdvertisersTable'
import PublisherListFilters from '../../components/_tables/PublisherListFilters'

import * as S from './styles'

const RetailMediaLinks = () => {
  const { t } = useAPPTranslation()

  // Informa ao gráfico qual a origem dos dados. Se pertence a advertiser ou publisher
  const originKey = isAdvertiser() ? 'publisher' : 'advertiser'
  const titleTerm = isAdvertiser() ? t('rm:publishers') : t('rm:advertisers')
  const title = t('common:capitalize', {
    value: titleTerm
  })

  const table = isAdvertiser() ? <PublisherTable /> : <AdvertisersTable />

  return (
    <>
      <PageTitle title={t('rm:dashboard.title', { title })} />

      <HeaderPages
        title={t('rm:dashboard.title', { title })}
        className="has-datepicker"
        sideRightComponent={
          <S.WrapperHeader>
            {isAdvertiser() && (
              <ButtonNavLink
                to="/retail-media/campaign/create"
                text={t('rm:createCampaign')}
                iconLeft={<FiPlus />}
              />
            )}

            <DatepickerHeader />
          </S.WrapperHeader>
        }
      />

      <MainTabs />

      {isPublisher() && (
        <section>
          <AdvertisersFilters />
        </section>
      )}

      {isAdvertiser() && (
        <section>
          <PublisherListFilters />
        </section>
      )}

      <DashboardRetailMediaCampaigns origin={originKey} />

      {table}
    </>
  )
}

export default RetailMediaLinks
